import { Injectable } from '@angular/core';

import { PopupService } from './popup.service';
import { Router } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { LocalStorageService } from './local-storage.service';
import { TermsGeneric } from 'src/helpers';

/**
 * Guard for preventing a user from accessing admin page if not authenticated/Authorized.
 */
@Injectable({
  providedIn: 'root',
})
export class AdminAuthGuard {
  constructor(
    private popupService: PopupService,
    private router: Router,
    private authGuard: AuthGuard,
    private localStorageService: LocalStorageService,
  ) {}

  /**
   * Determines if the user is signed in and can access admin routing action.
   * @returns True if the user is signed in and allowed to admin route, false otherwise.
   */
  async canActivate(): Promise<boolean> {
    return this.authGuard.canActivate().then((auth: boolean) => {
      if (!auth) {
        return false;
      } else {
        const user = JSON.parse(
          // eslint-disable-next-line keyword-spacing
          <string>this.localStorageService.getItem('user'),
        );
        if (user?.role === 'admin') {
          return true;
        } else {
          this.router.navigateByUrl(TermsGeneric.Board.Lower.Plural);
          this.popupService.notify(
            'Access denied. You do not have permission to view that page.',
            true,
          );
          return false;
        }
      }
    });
  }
}
