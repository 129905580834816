<div
  class="w-full flex gap-3 justify-between py-2 overflow-hidden items-center border-b border-secondary-200"
  [formGroup]="form"
>
  <div class="flex items-center gap-4 container-row w-[60%]">
    @if (isAddMember()) {
      <div class="ml-3">
        <mat-checkbox
          formControlName="canView"
          (change)="onChange()"
          [id]="'can-view-' + member().rithmId"
        ></mat-checkbox>
      </div>
    }
    <div [ngClass]="{ 'ml-3': !isAddMember() }">
      @if (isTeam()) {
        <div class="ml-[-10px]">
          <app-avatar-file
            [name]="getTeam().name"
            [photoVaultId]="getTeam().photoVaultId"
            [size]="'36'"
          />
        </div>
      } @else {
        <app-user-avatar
          [firstName]="getMember().firstName"
          [lastName]="getMember().lastName"
          [profileImageRithmId]="getMember().profileImageRithmId"
          [isDrawer]="true"
          [size]="'36'"
        />
      }
    </div>

    <div class="w-[210px] container-info">
      <p class="text-sm sm:text-base truncate pr-2">
        {{
          isTeam()
            ? getTeam().name
            : getMember().firstName + ' ' + getMember().lastName
        }}
      </p>
      @if (!isTeam()) {
        <p class="w-[85%] text-xs truncate text-primary-300">
          {{ getMember().email }}
        </p>
      }
    </div>
  </div>

  <div
    class="select-filter mt-2 md:mt-0 w-[40%] flex justify-between items-center"
  >
    <div
      [matTooltip]="
        ownUserRithmId() === member().rithmId
          ? 'You cannot change the role of this user because he/she is the ' +
            termsGeneric.Owner.Single +
            ' of the ' +
            termsGeneric.Board.Title.Single +
            '.'
          : ''
      "
    >
      <button
        mat-button
        type="button"
        id="open-menu-role"
        data-testid="open-menu-role"
        [matMenuTriggerFor]="menuFilters"
        class="text-xs"
        #Menu="matMenuTrigger"
        [disabled]="
          !canView() || isLoading() || ownUserRithmId() === member().rithmId
        "
        [ngClass]="{ 'animate-pulse': isLoading() }"
      >
        <span class="flex items-center p-2">
          {{
            getMember().canView && getMember().isEditable ? 'admin' : 'member'
          }}

          <i
            class="fa-light text-secondary-400 text-lg ml-2"
            [ngClass]="Menu.menuOpen ? 'fa-chevron-up' : 'fa-chevron-down'"
          ></i
        ></span>
      </button>

      <mat-menu #menuFilters="matMenu">
        <div class="menu-item-custom">
          @for (role of ['member', 'admin']; track $index) {
            <button
              mat-menu-item
              type="button"
              [id]="'role-menu-' + role"
              [attr.data-testid]="'role-menu-' + role"
              (click)="onChangeIsEditable(role)"
            >
              {{ role }}
            </button>
          }
        </div>
      </mat-menu>
    </div>
    @if (!isAddMember()) {
      <div>
        <button
          mat-icon-button
          type="button"
          id="open-menu-member"
          data-testid="open-menu-member"
          [matMenuTriggerFor]="menuMember"
          class="text-xs"
          #Menu="matMenuTrigger"
          [disabled]="!canView() || isLoading()"
        >
          <i class="fa-solid fa-ellipsis text-lg"></i>
        </button>

        <mat-menu #menuMember="matMenu">
          <button
            mat-menu-item
            type="button"
            id="menu-member"
            data-testid="menu-member"
            (click)="updateMemberEmit(enumUpdateType.Remove)"
          >
            <span class="text-error-500 text-base">
              Remove from
              {{
                isFolder() ? 'folder' : termsGeneric.Board.Lower.Single
              }}</span
            >
          </button>
        </mat-menu>
      </div>
    }
  </div>
</div>
