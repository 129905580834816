import { Injectable } from '@angular/core';

import { PopupService } from './popup.service';
import { UserService } from './user.service';

/**
 * Guard for preventing a user from accessing pages if not authenticated.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(
    private userService: UserService,
    private popupService: PopupService,
  ) {}

  /**
   * Determines if the user is signed in and can perform the attempted routing action.
   * @returns True if the user is signed in and allowed to route, false otherwise.
   */
  async canActivate(): Promise<boolean> {
    // If logged in, return true
    if (await this.userService.isSignedIn()) {
      if (!this.userService.user().rithmId.length) {
        this.signOut('User object destroyed');
        return false;
      }
      return true;
    } else {
      this.signOut('Session Expired');
      return false;
    }
  }

  /**
   * User should sign out and display error message in popup notify.
   * @param title Title for popup notify.
   */
  signOut(title: string): void {
    this.userService.signOut();
    this.popupService.notify(
      `${title}. You'll need to sign in again before you can view that page.`,
      true,
    );
  }
}
