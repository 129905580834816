<!-- Toast Notifications -->
<app-notification-toasts-container />

<!-- Top nav -->
@if (showTopNav) {
  <app-top-nav />
}

<mat-sidenav-container
  [ngClass]="{
    'mt-0 h-screen': !showTopNav,
    'mt-16 h-calc': showTopNav,
    'backdrop-drawer': showSideNav
  }"
  hasBackdrop="true"
>
  <!-- Side nav -->
  <mat-sidenav #sidenav mode="over" class="flex flex-col h-full !w-[360px]">
    @if (sidenavDrawerService.isSideNavOpen() && showSideNav && userLogged) {
      <app-menu-drawer [isArchitect]="userService.isAdmin" />
    }
  </mat-sidenav>

  <!-- Main page content -->
  <mat-sidenav-content
    class="flex-auto bg-gray-50"
    [ngClass]="{ 'non-entry': showTopNav, dark: darkEnabled }"
  >
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<mat-drawer-container
  class="!bg-transparent right-0 bottom-0 !z-[2000]"
  [hasBackdrop]="sidenavDrawerService.drawerHasBackdrop()"
>
  <mat-drawer
    #rightDrawer
    class="!fixed !bg-white !top-0 !z-[2000]"
    id="right-drawer"
    mode="over"
    position="end"
  >
    <app-info-drawer [isUsedFromMap]="isMap()" />
  </mat-drawer>
</mat-drawer-container>
