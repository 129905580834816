import { CommonModule } from '@angular/common';
import {
  Component,
  forwardRef,
  OnInit,
  OnDestroy,
  input,
  output,
  signal,
  computed,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormGroup,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subject, takeUntil } from 'rxjs';
import { AvatarFileComponent } from 'src/app/shared/avatar-file/avatar-file.component';
import { UserAvatarComponent } from 'src/app/shared/user-avatar/user-avatar.component';
import { TermsGeneric } from 'src/helpers';
import { MemberBoard, TeamBoard, UpdateType } from 'src/models';
interface MemberUpdate {
  /** Member. */
  member: MemberBoard | TeamBoard;
  /** Type of update. */
  updateType: UpdateType;
}

/** List members. */
@Component({
  selector: 'app-member-board-list-modal[member][ownUserRithmId]',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatCheckboxModule,
    MatMenuModule,
    MatButtonModule,
    MatTooltipModule,
    AvatarFileComponent,
    UserAvatarComponent,
  ],
  templateUrl: './member-board-list-modal.component.html',
  styleUrls: ['./member-board-list-modal.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MemberBoardListModalComponent),
      multi: true,
    },
  ],
})
export class MemberBoardListModalComponent
  implements OnInit, ControlValueAccessor, OnDestroy
{
  /** Subject for whether the component was destroyed/unsubscribe. */
  private destroyed$ = new Subject<void>();

  /** Member board. */
  member = input.required<MemberBoard | TeamBoard>();

  /** Id of the user who owns the board. */
  ownUserRithmId = input.required<string>();

  /** If is template to add member. */
  isAddMember = input.required<boolean>();

  /** Whether or not the modal is loading. */
  isLoading = input.required<boolean>();

  /** If it comes from a folder, otherwise board. */
  isFolder = input.required<boolean>();

  /** Status canViewAll. */
  canViewAll = input.required<boolean>();

  /** Deselect all members. */
  deselectCanViewAll = output<void>();

  /** Remove member. */
  updateMember = output<MemberUpdate>();

  /** Signal to get status canView. */
  canView = signal<boolean>(false);

  /** If the current item is a team. */
  isTeam = computed(() => 'name' in this.member());

  /** If the current item is member. */
  getTeam = computed(() => this.member() as TeamBoard);

  /** If the current item is a member. */
  getMember = computed(() => this.member() as MemberBoard);

  /** Form user. */
  form!: FormGroup;

  /** Member can view. */
  isCanView = false;

  /** Enum update type. */
  enumUpdateType = UpdateType;

  /** System-wide generic terms. */
  termsGeneric = TermsGeneric;

  /** Subject to listen changes for canView form. */
  private canViewChanges$(): void {
    this.form.controls['canView'].valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.canView.set(value);
      });
  }

  constructor(private fb: FormBuilder) {}

  /** Init method. */
  ngOnInit(): void {
    this.form = this.fb.group({
      canView: this.fb.control(this.member().canView),
      isEditable: this.fb.control(this.member().isEditable),
    });
    this.canViewChanges$();
    this.setInitialView();
  }

  /** Set the member view initially. */
  private setInitialView(): void {
    const canView = this.canViewAll();
    if (this.form) {
      if (canView) {
        this.isCanView = canView;
      }
      if (!this.isCanView) {
        this.form.patchValue({
          canView: canView,
          isEditable: false,
        });
      }
    }
  }

  /**
   * The `onTouched` function.
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched: () => void = () => {};

  /**
   * Set value in user.
   * @param value Set value.
   */
  // eslint-disable-next-line
  writeValue(value: any): void {
    if (value) {
      this.form.setValue(value);
    }
  }

  /**
   * Registers a function with the `onChange` event.
   * @param fn The function to register.
   */
  // eslint-disable-next-line
  registerOnChange(fn: any): void {
    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(fn);
  }

  /**
   * Registers a function with the `onTouched` event.
   * @param fn The function to register.
   */
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  /**
   * Status isEditable.
   * @param role The role of member.
   */
  onChangeIsEditable(role: string): void {
    if (this.canView()) {
      this.form.patchValue({
        isEditable: role === 'admin',
      });
      !this.isAddMember() && this.updateMemberEmit(UpdateType.Update);
    }
  }

  /**
   * Change canView.
   */
  onChange(): void {
    this.isCanView = this.canView();
    if (!this.canView()) {
      this.form.patchValue({
        isEditable: false,
      });
      if (this.canViewAll()) {
        this.deselectCanViewAll.emit();
      }
    }
  }

  /**
   * Update member.
   * @param updateType Type to update member.
   */
  updateMemberEmit(updateType: UpdateType): void {
    this.updateMember.emit({
      member: this.member(),
      updateType: updateType,
    });
  }

  /**
   * Completes all subscriptions.
   */
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
